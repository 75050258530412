<template>
<b-row class="pl-4">
  <b-col cols="12" md="11">
    <b-form-group label-cols-sm="12" label-cols-md="3" :id="'labelKeyMessage-' + messageGroupName + '-' + myMessageIndex" :label="$t('language.message-key')" :label-for="'keyMessage-'  + messageGroupName + '-' + myMessageIndex">
      <b-form-input :id="'key-message-'  + messageGroupName + '-' + myMessageIndex"
              v-model="myMessage.messageKey"
              :placeholder="$t('language.enter-key-message')">
      </b-form-input>
    </b-form-group>
    <b-form-group label-cols-sm="12" label-cols-md="3" :id="'labelValueMessage-'  + messageGroupName + '-' + myMessageIndex" :label="$t('language.message-value')" :label-for="'valueMessage-'  + messageGroupName + '-' + myMessageIndex">
      <b-form-input :id="'valueMessage-'  + messageGroupName + '-' + myMessageIndex"
              v-model="myMessage.message"
              :placeholder="$t('language.enter-value-message')">
      </b-form-input>
    </b-form-group>
  </b-col>
  <b-col cols="12" md="1" align-self="center">
    <BIconTrashFill variant="danger"  font-scale="1.3" :title="$t('language.message-remove')" @click="removeMessage(myMessageIndex, myMessage.message)"/>
  </b-col>
  <b-col cols="12"><hr></b-col>
</b-row>
</template>

<script>
import { BIconTrashFill } from 'bootstrap-vue'
export default {
  name : "Message",
  components : { BIconTrashFill },
  props: {message: Object, messageIndex: Number, messageGroupName: String},

  computed: {
    myMessage: function() {
      return this.message;
    },
    myMessageIndex: function() {
      return this.messageIndex;
    }
  },

  methods: {
    removeMessage: function(messageIndex, messageValue) {
      this.$emit("removeMessage", messageIndex, messageValue);
    }
  }

  //beforeCreate: function() { console.log("\t\tPozivam BeforeCreate funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },
  // created: function() { console.log("\t\tPozivam Created funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },
  // beforeMount: function() { console.log("\t\tPozivam BeforeMount funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },
  // mounted: function() { console.log("\t\tPozivam Mounted funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },
  // beforeUpdated: function() { console.log("\t\tPozivam BeforeUpdated funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },
  // updated: function() { console.log("\t\tPozivam Updated funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },
  // beforeDestroy: function() { console.log("\t\tPozivam BeforeDestroy funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },
  // destroy: function() { console.log("\t\tPozivam Destroy funkciju Message."); console.log("\t\tthis.message: "); console.log(this.message); },

}
</script>
<style></style>
