<template>
  <div class="p-2 position-relative">
    <b-form @submit="saveLanguage" autocomplete="off">
      <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('language.name')" label-for="languageName">
        <b-form-input id="languageName" v-model="myLanguage.name" :placeholder="$t('language.enter-language-name')"></b-form-input>
      </b-form-group>

      <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('language.shortName')" label-for="shortName">
        <b-form-input id="shortName" v-model="myLanguage.shortName" :placeholder="$t('language.enter-language-shortname')"></b-form-input>
      </b-form-group>


      <b-form-group label-cols-sm="12" label-cols-md="3" label-for="languageIANAShortName">
        <template #label>
          {{$t('language.IANAshortName')}} 
          <BIconQuestionSquareFill scale=".8" variant="primary" id="ianaDesc" />
          <b-popover target="ianaDesc" placement="top" triggers="hover" :title="$t('language.IANAshortName')" variant="info">
            <i18n path="language.IANAlink" tag="label" for="tos">
              <a href="https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry" target="_blank" rel="noopener">
                iana.org
              </a>
            </i18n>
            <!-- {{ $t('language.IANAlink') }}  -->
          </b-popover>
        </template>
        <b-form-input id="languageIANAShortName" v-model="myLanguage.IANA" :placeholder="$t('language.enter-language-IANA-shortname')"></b-form-input>
      </b-form-group>

      <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('language.icon')" label-for="icon">
        <b-row class="d-flex flex-row" v-if="myLanguage.icon">
          <b-col md="3" sm="4">
            <b-img thumbnail fluid :src="myLanguage.icon" id="thumbnail"></b-img>
          </b-col>
          <b-col md="9" sm="8" class="d-flex align-items-center">
            <BrowseButton name="myIcon" buttonText="Choose icon" accept="image/jpeg, image/png, image/gif, image/svg+xml"/>
          </b-col>
        </b-row>
        <b-row class="d-flex flex-row" v-else>
          <b-col>
            <BrowseButton name="myIcon" buttonText="Choose icon" accept="image/jpeg, image/png, image/gif, image/svg+xml"/>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('language.enabled')" label-for="languageEnabled">
        <b-form-checkbox id="languageEnabled" v-model="myLanguage.enabled" switch size="lg">
        <small v-if="myLanguage.enabled">
          {{$t('language.language-is-enabled')}}
        </small>
        <small v-else>
          {{$t('language.language-is-not-enabled')}}
        </small>
        </b-form-checkbox>
      </b-form-group>

      <!-- <small>{{myLanguage.messageGroup}}</small> -->
      <b-form-group label-cols-sm="12" label-cols-md="3" :label="$t('language.message-group')">
        <div v-for="(group, index) in myGroups" :key="index">
          <MessagesGroup :groups="group" :index="index" />
        </div>
      </b-form-group>

      <b-form-group label-cols-sm="12" label-cols-md="3" >
        <b-button type="button" variant="secondary" @click="addMessageGroup()">{{ $t('language.add-language-group')}}</b-button>
      </b-form-group>

      <b-form-group label-cols-sm="12" label-cols-md="3" >
        <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5" class="d-inline-block">
          <b-button type="submit" variant="primary" @submit="saveLanguage()">{{ $t('language.save-language')}}</b-button>
        </b-overlay>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import MessagesGroup from '@/components/admin/langauge/messages/MessagesGroup.vue'
import BrowseButton from '@/components/util/BrowseButton.vue'
import { BFormCheckbox, BImg, BIconQuestionSquareFill, BPopover } from 'bootstrap-vue'

export default {
  name: "LanguageForm",
  components: { MessagesGroup, BFormCheckbox, BImg, BrowseButton, BIconQuestionSquareFill, BPopover },
  data: function() {
    return {
      myLanguage : {},
      myGroups: [],
      saveSpinner: false
    }
  },

  created: function() {
    if (typeof this.$route.params.languageShortName !== "undefined") {
        this.$http.get("cms/api/languages/get/" + this.$route.params.languageShortName, { headers: {Authorization: this.getAuthData().accessToken } })
        .then(response => {
          //console.log(response);
          var messageGroups = JSON.parse(response.data.messages);
          //console.log(messageGroups);
          this.myLanguage = response.data;
          this.myLanguage.messageGroup = messageGroups;
          this.myGroups = messageGroups;
          //console.log(this.myLanguage);
          this.$emit('hideOverlay')
          delete this.myLanguage.messages;
        }).catch(e => {
          var message = this.handleError(e);
        })
    } else  {

    }

  },
  methods: {
    addMessageGroup: function() {
      //console.log("Dodajem poruke!");
      let myGroups1 = [];
      //console.log(this.myGroups);
      //console.log(this.myGroups1);
      const newMG = {name : '', messages: [{messageKey : "", message : ""}]};
      if (this.myGroups === null) {
        myGroups1.push(newMG);
      } else {
        myGroups1 = this.myGroups;
        myGroups1.push(newMG);
      }
      //console.log(myGroups1);
      this.myGroups = myGroups1;
    },

    saveLanguage : function(event) {
      event.preventDefault();
      this.saveSpinner = true;
      //console.log(this.myLanguage);
      var messageGroups = JSON.stringify(this.myGroups);
      this.myLanguage.messages = messageGroups;
      delete this.myLanguage.messageGroup;
      // console.log(this.myLanguage);
      // console.log(document.getElementById("myIcon"));

      const data = new FormData();
      // Tekstualne podatke šaljemo kao binarni objekat
      data.append("language", new Blob([JSON.stringify(this.myLanguage)], {type: "application/json"}));
      data.append("icon", document.getElementById("myIcon").files[0]);
      const axiosConfig = {
        headers: {"Content-Type": undefined, Authorization: this.getAuthData().accessToken}
      };
      this.$http.post("/cms/api/languages/save", data, axiosConfig)
        .then(r => {
          if (r.data === "OK") {
            this.$router.push({name : 'Languages'});
          }
      }).catch(error => {
        var message = this.handleError(error);
        this.saveSpinner = false;
      })
      /*

      this.$http.post("/cms/api/languages/save", this.myLanguage, {headers: {Authorization: this.getAuthData().accessToken}})
        .then(response => {
          // console.log(response);
          this.languages = response.data;
        })
        .catch(error => {
          var message = this.handleError(error);
          //this.$router.push({name : "Home"});
      })
      */
    },

    showImage: function (e) {
      var img = document.getElementById("thumbnail");
      this.showMyImage(this.imageFile, img);
    }
  }
}
//beforeCreate: function() { console.log("Pozivam BeforeCreate funkciju LanguageForm."); console.log("this.myLanguage: "); console.log(this.myLanguage); },
// created: function() { console.log("Pozivam Created funkciju LanguageForm."); console.log("this.myLanguage: "); console.log(this.myLanguage); },
// beforeMount: function() { console.log("Pozivam BeforeMount funkciju LanguageForm."); console.log("this.myLanguage: "); console.log(this.myLanguage); },
// mounted: function() { console.log("Pozivam Mounted funkciju LanguageForm."); console.log("this.myLanguage: "); console.log(this.myLanguage); },
// beforeUpdated: function() { console.log("Pozivam BeforeUpdated funkciju LanguageForm."); console.log("this.myLanguage: "); console.log(this.myLanguage); },
// updated: function() { console.log("Pozivam Updated funkciju LanguageForm."); console.log("this.myLanguage: "); console.log(this.myLanguage); },
// beforeDestroy: function() { console.log("Pozivam BeforeDestroy funkciju LanguageForm."); console.log("this.myLanguage: "); console.log(this.myLanguage); },
// destroy: function() { console.log("Pozivam Destroy funkciju LanguageForm"); console.log("this.myLanguage: "); console.log(this.myLanguage); },

</script>
<style></style>
