<template>
  <b-card no-body class="mb-2" bg-variant="light">
    <b-card-header  header-tag="header" class="p-1" role="tab" >
      <b-button block v-b-toggle :href="'#accordion-' + myIndex" variant="light" @click.prevent>
        {{ $t('language.message-group') }} <strong>{{myGroup.name}}</strong>
      </b-button>
    </b-card-header>
    <b-collapse :id="'accordion-' + myIndex" accordion="my-accordion" role="tabpanel">
      <b-card-body>

        <b-form-group :label="$t('language.message-group')" label-for="messageGroup">
          <b-form-input :id="'messageGroup' + myGroup.name"
                  v-model="myGroup.name"
                  :placeholder="$t('language.enter-message-group-name')">
          </b-form-input>
        </b-form-group>

        <div v-for="(message, index2) in myGroup.messages" :key="index2">
          <Message :message="message" :messageIndex="index2" :messageGroupName="myGroup.name" @removeMessage="removeMessage(index2)"/>
        </div>
        <b-form-group class="pl-4">
          <b-button type="button" variant="outline-primary" @click="addMessage()">{{ $t('language.add-message')}}</b-button>
        </b-form-group>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {BCard, BCollapse, BCardBody, BCardHeader} from 'bootstrap-vue'
import Message from "@/components/admin/langauge/messages/Message.vue"

export default {
  name: "MessagesGroup",
  components: {BCard, BCollapse, BCardBody, BCardHeader,  Message},
  props: { groups: Object, index: Number },

  computed:  {
    myGroup: function() {
      return this.groups;
    },
    myIndex: function() {
      return this.index;
    }
  },

  methods: {

    addMessage: function() {
      const newM = {messageKey : "", message : ""};
      const myMessage1 = this.myGroup.messages;
      myMessage1.push(newM);
      this.myGroup.messages = myMessage1;
    },

    removeMessage: function(index, value) {
      console.log(index, value);
      const myMessage1 = this.myGroup.messages;
      myMessage1.splice(index, 1);
      this.myGroup.messages = myMessage1;
    },

    addToMessageGroup: function (messageGroupIndex, messages) {
      //console.log(messageGroupIndex, messages);
      this.myMessageGroups[messageGroupIndex].messages = messages;
      // console.log("Dodajem messages u:" + messageGroupIndex);
    }
  }

  //beforeCreate: function() { console.log("\tPozivam BeforeCreate funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },
  // created: function() { console.log("\tPozivam Created funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },
  // beforeMount: function() { console.log("\tPozivam BeforeMount funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },
  // mounted: function() { console.log("\tPozivam Mounted funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },
  // beforeUpdated: function() { console.log("\tPozivam BeforeUpdated funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },
  // updated: function() { console.log("\tPozivam Updated funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },
  // beforeDestroy: function() { console.log("\tPozivam BeforeDestroy funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },
  // destroy: function() { console.log("\tPozivam Destroy funkciju MessagesGroup."); console.log("\tthis.groups: "); console.log(this.groups); },

}
</script>
<style></style>
